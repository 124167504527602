import ko from 'knockout'
import numeral from 'numeral'
import PriceUtils from 'utils/price'

import html from 'components/price.html'

const currencySettings = {
    USD: {leadingSymbol: '$'},
    AUD: {leadingSymbol: '$', trailingSymbol: '&nbsp;AUD'},
    NZD: {leadingSymbol: 'NZ$'},
    CAD: {leadingSymbol: '$', trailingSymbol: '&nbsp;CAD'},
    GBP: {leadingSymbol: '£'},
    EUR: {leadingSymbol: '€', leadingFont: 'Arial'},
    CHF: {leadingSymbol: 'CHF&nbsp;'},
    SEK: {trailingSymbol: '&nbsp;kr'},
    DKK: {trailingSymbol: '&nbsp;kr'},
    NOK: {trailingSymbol: '&nbsp;kr'},
    PLN: {trailingSymbol: '&nbsp;zł‎'},
    JPY: {leadingSymbol: '¥'}
}

export default class Price {
    constructor (data) {
        this.isNegative = ko.pureComputed(() => Number(ko.unwrap(data.price)) < 0)
        this.price = ko.pureComputed(() => Math.abs(Number(ko.unwrap(data.price))))
        this.discountedPrice = ko.pureComputed(() => {
            return data.discountedPrice ? Math.abs(Number(ko.unwrap(data.discountedPrice))) : null
        })

        this.currency = ko.pureComputed(() => ko.unwrap(data.currency))

        this.priceWhole = ko.pureComputed(() => {
            return PriceUtils.noDecimal(this.price())
        })

        this.priceDelimiter = numeral.localeData().delimiters.decimal

        this.priceDecimal = ko.pureComputed(() => {
            return PriceUtils.decimalOnly(this.price())
        })

        this.currencySetting = ko.pureComputed(() => {
            return currencySettings[this.currency() && this.currency().toUpperCase()] || {leadingSymbol: this.currency()}
        })
        this.leadingSymbol = ko.pureComputed(() => this.currencySetting().leadingSymbol || '')
        this.leadingFont = ko.pureComputed(() => this.currencySetting().leadingFont || 'inherit')
        this.trailingSymbol = ko.pureComputed(() => this.currencySetting().trailingSymbol || '')
    }
}

ko.components.register('VW.Components.Price', {
    template: html,
    viewModel: Price
})
