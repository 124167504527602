import axios from 'axios'
import config from '../components/config'

axios.defaults.xsrfCookieName = config.csrfCookie
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN'

const instance = axios.create({
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
    }
})

export default instance
