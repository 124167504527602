import ko from 'knockout'
import axios from 'utils/axios'
import numeral from 'numeral'
import moment from 'moment'

import Urls from 'urls'
import {Basket} from 'view-models/data/basket'
import {i18n} from '@lingui/core'

// register sub-components
import './basket-content'
import './basket-messages'

import {getPageLanguage, getParsedPageLanguage} from 'components/helpers'
import {Distributor} from 'view-models/data/distributor-prefix'
import {addUrlQueryParams, initAxiosRequestsWithParams, parseUrl, updateUrlWithCurrentParams} from 'utils/query-params'

import html from 'components/basket.html'

window.i18n = i18n

class BasketViewModel {
    constructor (params) {
        params = params || {}

        initAxiosRequestsWithParams()

        this.visible = ko.observable(false)
        this.basket = ko.observable(undefined)
        this.isReadOnly = params.readOnly || false
        this.countryLanguage = {}
        this.pageCountryLanguage = getParsedPageLanguage()
        this.distributor = ko.observable()
        this._continueShoppingUrl = ko.observable()

        this.continueShoppingUrl = ko.pureComputed(() => {
            return updateUrlWithCurrentParams(this._continueShoppingUrl())
        })

        this.initLocale()
        this.load()
    }

    getCheckoutUrl () {
        let params = {region: this.countryLanguage.countryCode}
        if (this.countryLanguage.languageCode) {
            params['lang'] = this.countryLanguage.languageCode
        }

        let url = addUrlQueryParams(Urls.checkoutPage, params)
        return updateUrlWithCurrentParams(url)
    }

    initLocale () {
        i18n.activate(this.pageCountryLanguage.languageCode)
        numeral.locale(numeral.locales[i18n.locale] ? i18n.locale : 'en')
        moment.locale(i18n.locale)
    }

    load () {
        return this._getCountryLanguage()
            .catch(() => {})
            .finally(() => {
                this._load()
            })
    }

    _load () {
        return Basket.repo.loadAll(null, true)
            .then(result => {
                this.basket(result && result.length ? result[0] : null)
                return this.basket()
            })
            .then(basket => {
                basket.continueShoppingEvent.addCallback(this.continueShopping.bind(this))
            })
            .catch(this._handleBasketError.bind(this))
    }

    _handleBasketError (error) {
        this.basket(new Basket())
        this.basket().setDistributor(this.distributor())
        this.basket().errorEvent.trigger(error)
        this.show()
    }

    addProduct (productCode, quantity, distributorPrefix) {
        return this._verifyCountryLanguage()
            .then(() => {
                return this.basket().addProduct(
                    productCode, quantity, distributorPrefix, this.countryLanguage.countryCode
                )
            })
            .catch((error) => this.basket().errorEvent.trigger(error))
    }

    show () {
        this.visible(true)
    }

    hide () {
        this.visible(false)
    }

    _isCurrentUrl (url) {
        url = parseUrl(url).url
        return url === window.location.pathname || url === window.location.origin + window.location.pathname
    }

    continueShopping () {
        this.hide()
        // check the url without the QPs
        if (this._continueShoppingUrl() && !this._isCurrentUrl(this._continueShoppingUrl())) {
            window.location = this.continueShoppingUrl()
        }
    }

    _setCountryLanguage () {
        const contentLanguage = getPageLanguage()
        return this._updateCountryLanguage(axios({
            url: Urls.countryLanguageSet,
            method: 'POST',
            data: {
                content_language: contentLanguage
            }
        }))
    }

    _getCountryLanguage () {
        return this._updateCountryLanguage(axios({
            url: Urls.countryLanguageCurrent,
            method: 'GET'
        }))
    }

    _updateCountryLanguage (countryLanguagePromise) {
        countryLanguagePromise
            .then(result => {
                this.countryLanguage = {
                    countryCode: result.data.country_code || '',
                    languageCode: result.data.language_code || ''
                }

                this.distributor(new Distributor(result.data.distributor.id, result.data.distributor))
            })
            .catch(() => {})

        return countryLanguagePromise
    }

    _verifyCountryLanguage () {
        if (this.countryLanguage.countryCode?.toUpperCase() === this.pageCountryLanguage.countryCode.toUpperCase()) {
            return Promise.resolve()
        }

        return this._setCountryLanguage()
    }
}

let basketViewModel = null

export default class BasketApi {
    getAsync () {
        return new Promise((resolve) => {
            if (this.get()) {
                resolve(this.get())
            }
            const _intervalId = setInterval(() => {
                if (this.get()) {
                    clearInterval(_intervalId)
                    resolve(this.get())
                }
            }, 1000)
        })
    }

    get () {
        return basketViewModel && basketViewModel.basket() ? basketViewModel.basket() : null
    }

    show () {
        if (!basketViewModel) {
            return
        }

        basketViewModel.show()
    }

    hide () {
        if (!basketViewModel) {
            return
        }

        basketViewModel.hide()
    }

    addProduct (productCode, quantity, distributorPrefix) {
        return this.getAsync().then(this._addProduct.bind(this, productCode, quantity, distributorPrefix))
    }

    _addProduct (productCode, quantity, distributorPrefix) {
        const result = basketViewModel.addProduct(productCode, quantity, distributorPrefix)
        this.show()
        return result
    }

    setConfig (config) {
        config.element.classList.add(config.basketEnabledClass || 'enabled')

        if (!basketViewModel) {
            window.setTimeout(this.setConfig.bind(this, config), 250)
            return
        }

        basketViewModel._continueShoppingUrl(ko.unwrap(config.continueShoppingUrl))
        ko.computed(this._updateBasketTrigger.bind(this, config))
    }

    _updateBasketTrigger (config) {
        const element = config.element
        const countElement = config.countElement
        const loadedClass = config.basketLoadedClass || 'loaded'
        const fullClass = config.basketFullClass || 'full'
        const basket = basketViewModel.basket()

        if (!basket) {
            return
        }

        element.classList.toggle(loadedClass, !basket.busy)

        const count = basket.count
        if (element) {
            element.classList.toggle(fullClass, count > 0)
        }

        if (countElement) {
            countElement.innerHTML = count ? String(count) : ''
        }
    }

    removeAllProducts () {
        if (!basketViewModel || !basketViewModel.basket()) {
            return
        }

        basketViewModel.basket().removeAllProducts()
    }
}

ko.components.register('VW.Components.Basket', {
    viewModel: function (params) {
        if (!basketViewModel) {
            basketViewModel = new BasketViewModel(params)
        }

        return basketViewModel
    },
    template: html
})
